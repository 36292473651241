import React from "react"
import {Link, graphql, useStaticQuery} from 'gatsby'

import  '../styles/blog.css'


import 'bootstrap/dist/css/bootstrap.min.css';


const BlogList = () => {
    const data = useStaticQuery(graphql `
    query {
        allContentfulBlogPost (
          sort: {
            fields: publishedDate,
            order: DESC
          }, limit: 3
        ) {
          edges {
            node {
              title
              slug
              preview
              publishedDate(formatString: "MMMM Do, YYYY")
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `)

    return (
        <div>
            <div className="blog-row main-blog" style={{width: "95%"}}>
              <ol>
                {data.allContentfulBlogPost.edges.map((edge) => {
                    return (
                      
                      <div className="column blog-column">
                        <li className="posts">
                          <Link to ={`/blog/${edge.node.slug}`} >
                          <img src={edge.node.image.file.url} style={{width: "100%"}}/>
                            <h4 style={{color: "black"}}>{edge.node.title}</h4>
                            <p>{edge.node.publishedDate}</p>
                            <p style={{ textJustify: "inter-word"}} className="preview-section">{edge.node.preview}</p>
                          </Link>
                        </li>
                      </div>
                    )
                })}
            </ol>
            </div>
            </div>
    )
}

export default BlogList